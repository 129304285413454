import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import flickity from 'flickity';
import 'flickity-imagesloaded';


(function($) {
    // Lazyload lozad.js
    $("img").each(function () {
        // voegt class 'lozad' toe aan alle img's voor Lazyload behalve img's in een carousel
        $(this).not('.carousel img').addClass('lozad');
        $(this).not('.carousel img').attr("data-src", $(this).attr("src"));
        $(this).not('.carousel img').removeAttr("src");
    });
    // lazy loads elements with default selector as '.lozad'
    const observer = lozad();
    observer.observe();

    // Sliders
    $(".carousel").each(function() {
        var carousel = $(this),
            carouselOptions = {
                arrowShape: 'M50,-2.13162821e-14 L12.430944,37.9571547 C7.85635201,42.5790034 7.85635201,50.0710143 12.430944,54.6928631 C17.0055359,59.3147118 24.4209436,59.3147118 28.9955356,54.6928631 L38.2852959,45.3071369 L38.2852959,88.1642798 C38.2852959,94.6975973 43.527626,100 50,100 C56.4665167,100 61.7147041,94.6975973 61.7147041,88.1642798 L61.7147041,45.3071369 L71.0044644,54.6928631 C73.2946891,57.0067464 76.287796,58.1607291 79.2867602,58.1607291 C82.2857245,58.1607291 85.2788314,57.0067464 87.569056,54.6928631 C92.143648,50.0710143 92.143648,42.5790034 87.569056,37.9571547 L50,-2.13162821e-14 Z',
                cellSelector: ".carousel__slide",
                imagesLoaded: true,
                lazyLoad: true,
                cellAlign: "left",
                wrapAround: true, 
                pageDots: false
            };

        if(carousel.hasClass("carousel--highlights")) {
            $.extend(true, carouselOptions, {
                cellAlign: 'left',
                contain: true,
                imagesLoaded: true, 
                lazyLoad: true,
                pageDots: true
            });
        }

    if (carousel.hasClass("carousel-slider")) {
        $.extend(true, carouselOptions, {
            cellAlign: 'left',
            contain: true
        });
    } 

    if (carousel.hasClass("carousel--photos")) {
        $.extend(true, carouselOptions, {
            arrowShape: 'M50,-2.13162821e-14 L12.430944,37.9571547 C7.85635201,42.5790034 7.85635201,50.0710143 12.430944,54.6928631 C17.0055359,59.3147118 24.4209436,59.3147118 28.9955356,54.6928631 L38.2852959,45.3071369 L38.2852959,88.1642798 C38.2852959,94.6975973 43.527626,100 50,100 C56.4665167,100 61.7147041,94.6975973 61.7147041,88.1642798 L61.7147041,45.3071369 L71.0044644,54.6928631 C73.2946891,57.0067464 76.287796,58.1607291 79.2867602,58.1607291 C82.2857245,58.1607291 85.2788314,57.0067464 87.569056,54.6928631 C92.143648,50.0710143 92.143648,42.5790034 87.569056,37.9571547 L50,-2.13162821e-14 Z',
            cellSelector: ".carousel__slide",
            imagesLoaded: true,
            lazyLoad: true,
            cellAlign: "center",
            wrapAround: true,
            pageDots: true
        });
    }

    if(carousel.hasClass('carousel--parents')) {
        $.extend(true, carouselOptions, {
            cellAlign: 'left',
            contain: true,
            watchCSS: true,
            adaptiveHeight: false,
            imagesLoaded: true,
            wrapAround: false
        });
    }
    
    if(carousel.hasClass('carousel--childs')) {
        if($(window).width() >= 992) {
        if(carousel.children().length > 4) {
                $.extend(true, carouselOptions, {
                    cellAlign: 'left',
                    contain: true,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                });
            } else {
                $.extend(true, carouselOptions, {
                    cellAlign: 'center',
                    contain: true,
                    adaptiveHeight: false,
                    imagesLoaded: true,
                    groupCells: carousel.children().length,
                    wrapAround: false
                });
            }
        }
        else {
            $.extend(true, carouselOptions, {
                cellAlign: 'center',
                contain: true,
                adaptiveHeight: false,
                imagesLoaded: true, 
                wrapAround: false
            });
        }
    }

    if(carousel.hasClass('carousel--grand-childs')) {
        if($(window).width() >= 992) {
            if(carousel.children().length > 4) {
                $.extend(true, carouselOptions, {
                    cellAlign: 'left', 
                    contain: true, 
                    adaptiveHeight: false,
                    imagesLoaded: true,
                });
            } else {
                $.extend(true, carouselOptions, {
                    cellAlign: 'center',
                    contain: true,
                    adaptiveHeight: false,
                    imagesLoaded: true, 
                    groupCells: carousel.children().length,
                    wrapAround: false
                });
            }
        } else {
            $.extend(true, carouselOptions, {
                cellAlign: 'center',
                contain: true,
                adaptiveHeight: false,
                imagesLoaded: true, 
                wrapAround: false
            });
        }
          if(carousel.children().length < 1) {
              carousel.parent().parent().css('display', 'none');
            //   carousel.css('display', 'none');
          }
      }
    
    var flkty = new flickity(this, carouselOptions);
    $('.btn--toggle').on('click', function(){
        var data_class = $(this).attr('data-class');
    
        $('.btn--toggle').removeClass("active"); 
        $(this).addClass("active");         
        
        $('.toggle').addClass('toggle-hidden');
        $('.pane--'+data_class).removeClass('toggle-hidden');
        $('.carousel--'+data_class+' .toggle').removeClass('toggle-hidden');
        if(data_class === 'children') {
            flkty.resize();
        } else if(data_class === 'grand-children') {
            flkty.resize();
        }
        else if(data_class === 'parents') {
            flkty.resize();
        }
        
    });
}); 


$('.pane--children .gender').each(function() {
    if($('html').attr('lang') === 'nl-NL') { 
        $(this).text('Kind');
    } else if($('html').attr('lang') === 'de-DE') {
        $(this).text('Kind');
    } else if($('html').attr('lang') === 'en-US') {
        $(this).text('Child');
    }
});
$('.pane--grand-children .gender').each(function() {
    if($('html').attr('lang') === 'nl-NL') {
        $(this).text('Kleinkind');
    } else if($('html').attr('lang') === 'de-DE') {
        $(this).text('Child');
    } else if($('html').attr('lang') === 'en-US') {
        $(this).text('Grandchild');
    }
});


    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true
    });

    $( ".mobile-menu" ).click(function() {
        $('.mobile-menu').toggleClass('open');
        $('.mobile-nav').toggleClass('open');
    });


$('.dove-category').on('click', function() {
    $(".dove-category").removeClass("active");  // remove active class from all
    $(this).addClass("active");         // add active class to clicke
});


$('.sidebar-toggle').on('click touch', function() {
    if($('.sidebar').hasClass('open')) {
        $('.sidebar').removeClass('open');
        $(this).html('Toon filters');
    } else {
        $('.sidebar').addClass('open');
        $(this).html('Verberg filters');
    }
});

$('iframe').each(function() {
    var filmwidth = $(this).width();
    var filmheight = filmwidth / 16 * 9;
    $(this).height(filmheight);
});

$(document).ready(function() {
    setTimeout(function() {
        $(document).trigger('afterready');
    }, 100);
});

$(document).on('afterready', function() {
    if($('.facetwp-dropdown option:contains("Doffer")')) {
        var lang = $('html').attr('lang');

        var doffer = $('.facetwp-dropdown option:contains("Doffer")');
        var duivin = $('.facetwp-dropdown option:contains("Duivin")');

        var toTranslateDoffer = doffer.text().split(" ");
        var toTranslateDuivin = duivin.text().split(" ");
        if(lang === 'en-US') {
            toTranslateDoffer[0] = 'Cock';
            toTranslateDuivin[0] = 'Hen';
        } else if(lang === 'de-DE') {
            toTranslateDoffer[0] = 'Vogel';
            toTranslateDuivin[0] = 'Henne';
        } 
        toTranslateDoffer = toTranslateDoffer.toString();
        toTranslateDuivin = toTranslateDuivin.toString();

        toTranslateDoffer = toTranslateDoffer.replace(",", " ");
        toTranslateDuivin = toTranslateDuivin.replace(",", " ");

        doffer.text(toTranslateDoffer);
        duivin.text(toTranslateDuivin);
    }

});

})($); 
 